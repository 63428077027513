/*
 Authentication View.
*/

import React from 'react'
import { useLocation } from 'react-router-dom'
import style from './index.module.scss'
import { TENANT } from '../../constants/AuthX'
import SignOnFederated from './SignOnFederated'
import SignOnLocal from './SignOnLocal'
import { authSignOut, getAccessToken } from '../../utils/authx'
// import debug from '../../utils/debug'

////////////////////////////////////////////////////////////////////////////////
export function Login({ context }) {
  const location = useLocation()

  if (location.pathname === '/signout') {
    // debug('[AuthX] -> signout')
    authSignOut(context)
  } else {
    // debug('[AuthX] -> getAccessToken')
    getAccessToken(context)
    if (context.isSignedIn) {
      console.log('[AuthX] signed in?')
      return null
    }
  }

  const boxPadding = 'ph3 ph4-ns'
  return (
    <>
      {TENANT.heading}
      <div className={`flex items-start justify-center ${style.authbox}`}>
        <div className="w-100">
          <SignOnLocal boxPadding={boxPadding} context={context} />
          <div
            className={`${style.innerpane} ${boxPadding} items-center ba b--transparent`}
          >
            <SignOnFederated context={context} tenant={TENANT} />
          </div>
          <div
            className={`${style.innerpane} ${boxPadding} pv2 pv3-m pv4-l items-center ba b--transparent`}
          >
            {TENANT.terms}
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
