import jwt from 'jsonwebtoken'
import uuid from 'uuid/v4'
import { readableError } from '../../components/Handlers'
import { getProfile } from './profile'
import { safeStoreGet, safeStorePut, safeStoreDrop } from './safeLocalStore'
import debug from '../debug'

// level 0 is nothing, higher levels are more verbosity
const DEBUG_LEVEL = 2
// export function authDebug(level, message, data) {
//   if (level <= DEBUG_LEVEL) {
//     debug(message, data)
//   }
// }
export const authDebug = (x, y, z) => {}

export const VALIDATION_KEY = 'pdv'
export const ACCESS_KEY = 'pda'

////////////////////////////////////////////////////////////////////////////////
export function authSignOn({ context, vars, status, loading }) {
  authDebug(1, '[utils/authx] authSignOn()]')
  authRequest(context, 'signon', {
    body: JSON.stringify(vars)
  })
    .then(data => postSignOn({ context, status, loading, data }))
    .catch(error => authError({ status, loading, error }))
}

////////////////////////////////////////////////////////////////////////////////
export function authRequest({ config }, path, opts) {
  authDebug(3, '[utils/authx] authRequest() path=', path)
  if (!opts.method) {
    opts.method = 'POST'
  }
  if (!opts.headers) {
    opts.headers = {}
  }
  if (!opts.headers['Content-Type']) {
    opts.headers['Content-Type'] = 'application/json'
  }
  opts.credentials = 'same-origin'
  return fetch(config.baseurl + config.authapi + path, opts).then(res =>
    res.json()
  )
}

////////////////////////////////////////////////////////////////////////////////
export function authNotify(
  { status: { status, setStatus }, loading: { loading, setLoading } },
  text,
  color
) {
  authDebug(3, '[utils/authx] authNotify()')
  if (!color) {
    color = 'dark'
  }
  if (setStatus) {
    setStatus(text)
  } else {
    console.log(`CANNOT NOTIFY: ${text}`)
  }
  if (setLoading) {
    setLoading(false)
  }
}

////////////////////////////////////////////////////////////////////////////////
function postSignOn({ context, status, loading, data }) {
  authDebug(2, '[utils/authx] postSignOn() data=', data)
  if (data.aud && data.sec && data.sub) {
    setValidation({
      user: context.user,
      token: {
        audience: data.aud,
        secret: data.sec,
        subject: data.sub
      }
    })
    refreshAccessToken({
      context,
      status,
      loading
    })
  } else if (data.reason) {
    authNotify({ status, loading }, data.reason, 'red')
  } else {
    authNotify(
      { status, loading },
      'response received from backend with no validation token? cannot continue',
      'red'
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
export function authError({ status, loading, error }) {
  authDebug(3, '[utils/authx] authError() err=', error)
  authNotify({ status, loading }, readableError(error), 'red')
}

////////////////////////////////////////////////////////////////////////////////
export function refreshAccessToken({ context, status, loading }) {
  authDebug(3, '[utils/authx] refreshAccessToken()')
  if (getValidation(context)) {
    authRequest(context, 'refresh', {
      body: JSON.stringify({
        client_assertion_type:
          'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
        client_assertion: genRefreshToken(context)
      })
    })
      .then(data => {
        authDebug(1, '[utils/authx] refreshAccessToken() handling response...')
        if (data.access_token) {
          setAccessToken({ user: context.user, token: data.access_token })
          authSignedIn(context)
          // context.signedIn.update(true)
          // user.event.emit(AUTH_SIGNIN) // downstream: does a redirect
        } else {
          authNotify({ status, loading }, 'unexpected result from backend')
        }
      })
      .catch(error => authError({ status, loading, error }))
  } else {
    authNotify({ status, loading }, 'Unable to find validation token!', 'red')
  }
  return false
}

export function refreshAccessTokenPromise({ context, status, loading }) {
  authDebug(3, '[utils/authx] refreshAccessTokenPromise()')
  return authRequest(context, 'refresh', {
    body: JSON.stringify({
      client_assertion_type:
        'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
      client_assertion: genRefreshToken(context)
    })
  }).then(data => {
    authDebug(1, '[utils/authx] refreshAccessToken() handling response...')
    if (data.access_token) {
      setAccessToken({ user: context.user, token: data.access_token })
      authSignedIn(context)
    } else {
      authNotify({ status, loading }, 'unexpected result from backend')
    }
    return data
  })
  // if adding in the conditional again, just add Promise.resolve() for noop
}

////////////////////////////////////////////////////////////////////////////////
export function genRefreshToken({ user }) {
  if (user.validation_token) {
    authDebug(2, '[utils/authx] genRefreshToken() from validation')
    const { secret, subject, audience } = user.validation_token
    return jwt.sign(
      {
        jti: uuid(),
        sub: subject,
        aud: audience
      },
      secret,
      { expiresIn: 10 * 60 }
    )
  }
  authDebug(
    2,
    '[utils/authx] genRefreshToken() no validation, cannot generate refresh',
    '()'
  )
}

////////////////////////////////////////////////////////////////////////////////
export function getAccessToken(context) {
  const { user, signedIn } = context

  // FIRST see if we have a valid AccessToken available
  if (!user.access_token) {
    const token = safeStoreGet(ACCESS_KEY)
    if (token) {
      if (keepAccessToken({ user, token })) {
        authDebug(2, '[utils/authx] getAccessToken => [local cached token]')
        if (!signedIn.active) {
          authSignedIn(context)
        }
        return user.access_token
      }
      safeStoreDrop(ACCESS_KEY)
    }
  } else {
    if (user.access_token_expires > Date.now()) {
      authDebug(2, '[utils/authx] getAccessToken => [mem cached token]')
      if (!signedIn.active) {
        authSignedIn(context)
      }
      return user.access_token
    } else {
      authDebug(2, '[utils/authx] getAccessToken => token is expired!')
      removeAccessToken({ user })
    }
  }

  // SECOND, try to refresh with a validation token
  const validation = getValidation({ user })
  if (validation) {
    return refreshAccessToken({ context, status: {}, loading: {} })
  }
  authDebug(
    1,
    '[utils/authx] getAccessToken() FAIL - no access token, no validation, no auth => false'
  )
  // no valid validation token, mark us as no longer signed in
  authSignOut(context)
  return false
}

function validAccessToken(token) {
  authDebug(3, '[utils/authx] validAccessToken()')
  if (!token) {
    return {}
  }
  const claims = jwt.decode(token)
  if (Date.now() / 1000 < claims.exp) {
    return { claims, token, expires: claims.exp * 1000, valid: true }
  }
  return {}
}

function keepAccessToken({ user, token }) {
  authDebug(3, '[utils/authx] keepAccessToken()')
  const { valid, expires } = validAccessToken(token)
  if (valid) {
    user.access_token = token
    user.access_token_expires = expires
    return true
  } else {
    authDebug(3, '[utils/authx] keepAccessToken() => current token is expired!')
  }
  return false
}

function setAccessToken({ user, token }) {
  authDebug(3, '[utils/authx] setAccessToken()')
  if (keepAccessToken({ user, token })) {
    safeStorePut(ACCESS_KEY, token)
  }
}

function removeAccessToken({ user }) {
  authDebug(3, '[utils/authx] removeAccessToken()')
  safeStoreDrop(ACCESS_KEY)
  user.access_token = undefined
  user.access_token_expires = 0
}

////////////////////////////////////////////////////////////////////////////////
function authSignedIn(context) {
  const { signedIn, userProfile } = context
  authDebug(1, '[utils/authx] authSignedIn() signedIn.active=', signedIn.active)
  if (!signedIn.active) {
    authDebug(2, '[utils/authx] signedIn.update()...')
    signedIn.update(true)
  }
  if (!userProfile.current) {
    authDebug(2, '[utils/authx] calling update profile...')
    getProfile(context)
  } else {
    authDebug(2, '[utils/authx] profile looks good', userProfile.current)
  }
}

export function authSignOut({ user, apollo, signedIn, userProfile }) {
  authDebug(1, '[utils/authx] authSignOut()') // , {user, apollo, signedIn, userProfile} )
  if (signedIn.active) {
    signedIn.update(false)
  }
  if (userProfile.current) {
    userProfile.update(undefined)
  }
  removeValidation({ user })
  removeAccessToken({ user })
  if (apollo) {
    apollo.cache.reset()
  }
}

function setValidation({ user, token }) {
  authDebug(3, '[utils/authx] setValidation()', token)
  let { audience, secret, subject } = token
  if (audience && secret && subject) {
    safeStorePut(VALIDATION_KEY, token)
    user.validation_token = token
  }
}

function removeValidation({ user }) {
  authDebug(3, '[utils/authx] removeValidation()')
  user.validation_token = undefined
  safeStoreDrop(VALIDATION_KEY)
}

function getValidation({ user }) {
  if (!user.validation_token) {
    let validation
    try {
      validation = safeStoreGet(VALIDATION_KEY)
      if (validation) {
        if (!validation.audience) {
          authDebug(3, '[utils/authx] getValidation() !! Bad audience', 1)
          removeValidation({ user })
        }
      }
      user.validation_token = validation
    } catch (err) {
      authDebug(3, '[utils/authx] getValidation() !! Oops', err)
    }
    if (!validation) {
      authDebug(3, '[utils/authx] getValidation() cannot find validation')
      return undefined
    } else {
      user.validation_token = validation
    }
  }
  authDebug(3, '[utils/authx] getValidation() GOOD => return validation')
  return user.validation_token
}

////////////////////////////////////////////////////////////////////////////////
