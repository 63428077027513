import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { navigate } from '../../utils/history'

// import { AUTH_SIGNIN, AUTH_SIGNOUT } from '../../constants/Auth'
import style from './NavBar.module.scss'
import { MenuList, MenuItem, MenuButton } from 'react-menu-list'
import NavLink from '../../components/Link'
import { hasRole } from '../../utils/authx/profile'
// import debug from '../../utils/debug'
const debug = (x, y) => {}

// react-menu-list
function MenuLink(props) {
  return (
    <MenuItem>
      <NavLink to={props.to} className={`${style.navlink} w-100 tl`}>
        {props.children}
      </NavLink>
    </MenuItem>
  )
}

// todo:
// + capitalize when state selected
// + add icons (gigstartup.xyz has them)
// + responsive collapse menus
export function NavBar({ context }) {
  const { signedIn, userProfile } = context
  const location = useLocation()
  const history = useHistory()

  const atSignin = ['/signon', '/signout'].includes(location.pathname)

  if (atSignin) {
    if (signedIn.active) {
      debug('[NavBar] redirecting...')
      navigate(history)
    } else {
      debug('[NavBar] Still at signon, no navbar')
      return <></>
    }
  }

  if (!signedIn.active) {
    debug('[NavBar] !active, ?? (return null)')
    // navigate(history, `/signon`)
    return null
  }

  const isSuperAdmin = hasRole('superadmin', context)
  debug('[NavBar] render - ', [
    isSuperAdmin,
    context.userProfile.current,
    location.pathname
  ])
  let name
  if (userProfile.current) {
    name = userProfile.current.name
  }
  // const { name } = userProfile.current
  return (
    <div className="navbar ma0 pa1 flex justify-between items-center white">
      <div>
        <NavLink to="/" className={style.navlink}>
          innovate
        </NavLink>
        <NavLink to="/connect/" className={style.navlink}>
          connect
        </NavLink>
        <NavLink to="/topic/" className={style.navlink}>
          learning
        </NavLink>
        <NavLink to="/dashboard/" className={style.navlink}>
          dashboard
        </NavLink>
      </div>
      <div className="ml-auto">
        <MenuButton
          menu={
            <div
              className="pv1 pv2-m pv2-l white mt1"
              style={{
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(0,0,0,.5)'
              }}
            >
              <MenuList>
                <MenuLink to="/me">profile</MenuLink>
                <MenuLink to="/signout">signout</MenuLink>
                {isSuperAdmin ? (
                  <div className="bt b--gray pt2">
                    <MenuLink to="/adm-journeys">journey admin</MenuLink>
                    <MenuLink to="/adm-journey-maps">
                      journey map admin
                    </MenuLink>
                    <MenuLink to="/adm-users">user admin</MenuLink>
                  </div>
                ) : (
                  <></>
                )}
              </MenuList>
            </div>
          }
          style={{ backgroundColor: 'transparent', color: 'white' }}
        >
          {name || 'me'}
          <i className="pl2 fas fa-sort-down" />
        </MenuButton>
      </div>
    </div>
  )
}

export default NavBar
