import React, { useState } from 'react'
import { doSignOnFederated } from './doSignOnFederated'
import GoogleAuth from './GoogleAuth'
import StatusBox from './StatusBox'

export function SignOnFederated({ context, tenant }) {
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <>
      <div className="hrstrike pt3 pb3">
        <div className="f7 gray">or</div>
      </div>
      <div className="flex justify-center items-center">
        <GoogleAuth
          config={tenant}
          onSuccess={({ type, profile, authResponse }) =>
            doSignOnFederated({
              status: { status, setStatus },
              loading: { loading, setLoading },
              context,
              type,
              profile,
              authResponse
            })
          }
          onFailure={e => {
            console.log('FAILURE', e)
          }}
        />
      </div>
      <StatusBox message={status} loading={loading} />
    </>
  )
}
export default SignOnFederated
