import React from 'react'
import { useHistory } from 'react-router-dom'
import { navigate } from '../utils/history'
// import { Link } from 'react-router-dom'

function NavLink(props) {
  /*
  const { children, className, ...other } = props
  let classes = (className || '') + ' pa1 pa2-m pa2-l'
  console.log("CLASSES", other)
  return <Link className={classes} {...other}>{children}</Link>
  */
  const { children, className, to, nav, ...other } = props
  let classes = (className || '') + ' pa1 pa2-m pa2-l'
  const history = useHistory()
  return (
    <button
      onClick={() => {
        // eslint-disable-line
        navigate(history, to)
      }}
      className={classes}
      {...other}
    >
      {children}
    </button>
  )
}

export default NavLink
