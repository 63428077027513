import React, { useState, useEffect } from 'react'
import FuzzBox from './FuzzBox'

export function handleWaitForLoad({ loading, error }) {
  if (loading) {
    return <LoadingOverlay />
  }
  if (error) {
    return <ErrorBox>{readableError(error)}</ErrorBox>
  }
  return null
}

export function LoadingInline(props) {
  return (
    <div className="flex">
      {props.children || <>Loading...</>}
      <div className="loading-inline" />
    </div>
  )
}
// for when loading may take longer
export function LoadingOverlay(props) {
  const [showFull, updateShowFull] = useState(false)

  // DOC: this effect tracks a mount/unmount flag, so it does not update
  // state if the component is not active (mounted)
  useEffect(() => {
    let isMounted = true
    setTimeout(() => {
      if (isMounted) {
        updateShowFull(true)
      }
    }, 300)

    // return the function to "unmount"
    return () => (isMounted = false)
  })

  if (showFull) {
    return (
      <div className="loading-overlay">
        <div className="loading"></div>
        <div className="loading-body">{props.children || <>Loading...</>}</div>
      </div>
    )
  } else {
    return <>Loading...</>
  }
}

// note: something isn't working right in this parsing, it's getting called twice,
// the first time it matches, the second time it comes back null, grr
function matchError({
  graphQLErrors,
  networkError,
  operation,
  forward,
  message
}) {
  /*
  if (graphQLErrors) {
    console.log("GraphQL", graphQLErrors)
  }
  if (networkError) {
    console.log("Network", networkError)
  }
  console.log("ops", operation)
  console.log("forward", forward)
  console.log("msg", message)
  */
  return message
}
function errorString(err) {
  let message = matchError(err)
  if (message) {
    return message
  } else {
    message = err.toString()
    if (message) {
      return message
    } else {
      return 'Unexpected error, please try again in a few minutes'
    }
  }
}
export function readableError(err) {
  const backend =
    'Unexpected response from backend, cannot continue, please try again in a few minutes'
  const message = errorString(err)
  console.log(err.message)
  return message
    .replace(/Network error: Unexpected token P in JSON at position 0/, backend)
    .replace(/^.*Unexpected token < in JSON .*$/, backend)
    .replace(/^.*Failed to fetch.*$/, backend)
    .replace(/^Network error: /, '')
    .replace(/Server /i, 'backend ')
    .replace(/GraphQL error: /i, '')
}

export function ErrorBox(props) {
  return (
    <div className="flex justify-center">
      <FuzzBox tint="light" className="tc pa3 black">
        {readableError(props.children)}
      </FuzzBox>
    </div>
  )
}

export default handleWaitForLoad
