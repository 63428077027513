import { ApolloLink } from 'apollo-link'
import { ApolloClient } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { getAccessToken, refreshAccessToken, refreshAccessTokenPromise, authError } from './utils/authx'
import { GraphQLError } from 'graphql'
import { fromPromise, concat } from 'apollo-link'

import context from './constants/context.js'
// import debug from './utils/debug'
const debug = (x,y) => {}

const httpLink = createHttpLink({
  uri: context.config.baseurl + context.config.graphql,
  credentials: 'same-origin'
})

const authLink = setContext(async (_, { headers }) => {
  let token = getAccessToken(context)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

// an apollo link for error handling and refreshing if we are expired and need auth

// need to fix - still doesn't work 100% correctly.  Problem: it doesn't retry
// A few things to look into:
//
// - most promising:
//   https://able.bio/AnasT/graphql-apollo-async-token-refresh--470t1c8
//
// - doesn't support graphql errors (sigh)
//   https://www.apollographql.com/docs/link/links/retry/
//
// - dated solution, but maybe works.. just wrap GraphQL calls:
//   https://medium.com/@lucasmcgartland/refreshing-token-based-authentication-with-apollo-client-2-0-7d45c20dc703

let isRefreshing = false
let pendingRequests = []

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    console.log('apolloLink onError', graphQLErrors)
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // switch (err.extensions.code) {
        //   case 'UNAUTHENTICATED':
        switch (err.message) {
          case 'Unauthenticated':
            debug('[apollo errorLink] unauthenticated error, refreshing...')
            /////// if it were inline
            // refreshAccessToken({ context, loading: {}, status: {} })
            // return forward(operation)
            ///////

            // let token = getAccessToken(context)
            // const headers = operation.getContext().headers;
            // operation.setContext({
            //   headers: {
            //     ...headers,
            //     authorization: token ? `Bearer ${token}` : ''
            //   }
            // })

            /////// async

            let forward$
            if (!isRefreshing) {
              isRefreshing = true
              forward$ = fromPromise(
                refreshAccessTokenPromise({ context, loading: {}, status: {} })
                  .then(data => {
                    debug('[apollo errorLink] intercepting and refreshing token')
                    let token = getAccessToken(context)
                    const headers = operation.getContext().headers
                    operation.setContext({
                      headers: {
                        ...headers,
                        authorization: token ? `Bearer ${token}` : ''
                      }
                    })
                    resolvePendingRequests()
                    return data
                  })
                  .catch(error => {
                    pendingRequests = []
                    authError({ status: {}, loading: {}, error })
                  })
                  .finally(() => {
                    isRefreshing = false
                  })
              )
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                new Promise(resolve => {
                  pendingRequests.push(() => resolve())
                })
              )
            }

            return forward$.flatMap(() => forward(operation))

          default:
            debug('[apollo errorLink] errors', err)
        }
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  }
)

export const apollo = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache()
})

export default apollo
