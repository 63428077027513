import React from 'react'

// eventually query the backend for this
export const TENANT = {
  heading: (
    <div className="pt4">
      <div className="pa3 f2 lh-copy fw1 tc white">
        <i>Home U</i>
      </div>
      <div className="pb5 f5 lh-copy fw1 tc white">
        <i>Student Housing has never been easier!</i>
      </div>
    </div>
  ),
  terms: (
    <div className={`f7 tc`}>
      <i>
        By using this account you agree to our{' '}
        <a href="/tbd">Privacy and Use Policy</a>
      </i>
    </div>
  ),
  passport_info: false,
  background: {
    type: 'random-picture'
  },
  federated: {
    google: {
      enabled: true,
      appId:
        '528833200875-4ec9c5r2op5u59td53je8qk7o7i1c26b.apps.googleusercontent.com'
    }
  }
}
