import debug from '../../utils/debug'
import { authSignOn } from '../../utils/authx'

export function doSignOnFederated({
  status,
  loading,
  context,
  type,
  profile,
  authResponse
}) {
  debug('[AuthX].doSignOnFederated()', {
    status,
    loading,
    context,
    type,
    profile,
    authResponse
  })
  loading.setLoading(true)
  authSignOn({
    context,
    status,
    loading,
    vars: {
      type: type,
      data: { profile: profile, auth: authResponse }
    }
  })
}
