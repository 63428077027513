import debug from '../../utils/debug'
import { authNotify, authSignOn } from '../../utils/authx/'

export function doSignOnLocal({
  signup,
  handle,
  password,
  context,
  loading,
  status
}) {
  debug('[AuthX].doLocalSignOn()', '')
  loading.setLoading(true)

  let vars = {
    signup: signup,
    factor: 'email', // in the future: let people choose factors to auth with
    handle: handle.value.trim(),
    password: password.value.trim(),
    email: ''
  }

  if (signup) {
    vars.email = vars.handle
  }

  if (vars.handle.length === 0) {
    authNotify({ status, loading }, 'Please provide an email address!', 'red')
    return false
  }
  if (vars.password.length === 0) {
    authNotify({ status, loading }, 'Please provide a password!', 'red')
    return false
  }

  authSignOn({ context, status, loading, vars })
}
