export const UNIVERSITIES = [
"Agnes Scott College",
"American University",
"Aquinas College",
"Arizona State University",
"Auburn University",
"Ball State University",
"Baylor University",
"Belmont University",
"Benedict College",
"Bentley University",
"Bethel College",
"Boise State University",
"Boston Architectural College",
"Boston College",
"Boston University",
"Brigham Young University",
"California State University - Fullerton",
"California State University - Long Beach",
"California State University - Northridge",
"Catholic University",
"Central Michigan University",
"Clark Atlanta University",
"Clemson University",
"Colorado State University",
"Community College of Philadelphia",
"Cornish College of the Arts",
"Drexel University",
"Eastern Michigan University",
"Edgewood College",
"Emerson College",
"Emory University",
"Fisk University",
"Florida A&M University",
"Florida International University",
"Florida State University",
"Fullerton College",
"Gallaudet University",
"George Mason University",
"George Washington University",
"George Washington University - Mount Vernon",
"Georgetown University",
"Georgia Institute of Technology",
"Georgia Southern University",
"Georgia State University",
"Golden Gate University - Los Angeles Campus",
"Gonzaga University",
"Hamline University",
"Harvard University",
"Hope International University",
"Howard University",
"Indiana University",
"Indiana University - South Bend",
"Iowa State University",
"Irvine Valley College",
"Kansas State University",
"Kennesaw State University",
"Knoxville College",
"Liberty University",
"Los Angeles City College",
"Louisiana State University",
"Lubbock Christian University",
"Marymount University",
"Massachusetts Institute of Technology",
"Michigan State University",
"Middle Tennessee State University",
"Mississippi State University",
"Morehouse College",
"Morehouse School of Medicine",
"Murray State University",
"National Labor College",
"National University",
"New England Research Institute",
"North Seattle Community College",
"Northeastern University",
"Northern Arizona University",
"Orange Coast College",
"Parkland College",
"Piedmont Virginia Community College",
"Pima Community College",
"Pima Community College - West Campus",
"Princeton University",
"Purdue University",
"Rice University",
"Rutgers University - Camden Campus",
"Saint Edwards University",
"Saint Mary's College",
"San Diego State University",
"San Francisco State University",
"San Jose State University",
"Santa Clara University",
"Savannah College of Art and Design - Atlanta",
"Seattle Community College",
"Seattle University",
"Southern Connecticut State University",
"Southern Wesleyan University",
"Spelman College",
"Stanford University",
"Tallahassee Community College",
"Temple University",
"Tennessee State University",
"Texas A&M University",
"Texas A&M University - School of Law",
"Texas Christian University",
"Texas Southern University",
"Texas State University",
"Texas Tech University",
"Texas Woman's University",
"Thomas Jefferson University",
"Transylvania University",
"Trevecca Nazarene University",
"Tufts University",
"University of Alabama",
"University of Arizona",
"University of Arkansas",
"University of California - Berkeley",
"University of California - Davis",
"University of California - Irvine",
"University of California - Los Angeles",
"University of Central Florida",
"University of Colorado - Boulder",
"University of Connecticut - Stamford",
"University of Florida",
"University of Georgia",
"University of Houston",
"University of Houston - Downtown",
"University of Illinois - Urbana-Champaign",
"University of Iowa",
"University of Kansas",
"University of Kentucky",
"University of Maryland",
"University of Massachusetts - Boston",
"University of Miami",
"University of Michigan",
"University of Minnesota",
"University of Mississippi",
"University of Missouri",
"University of Nebraska - Lincoln",
"University of Nevada - Las Vegas",
"University of North Texas",
"University of Notre Dame",
"University of Pennsylvania",
"University of Richmond",
"University of Saint Thomas",
"University of San Diego",
"University of San Francisco",
"University of South Carolina",
"University of South Florida",
"University of Southern California",
"University of Southern California - Health Science Campus",
"University of Tennessee",
"University of Texas at Arlington - Fort Worth Center",
"University of Texas at Austin",
"University of Utah",
"University of Virginia",
"University of Washington",
"University of Washington School of Medicine",
"University of Wisconsin - Madison",
"University of Wyoming",
"Utah State University",
"Utah Valley University",
"Vanderbilt University",
"Virginia Commonwealth University",
"Virginia Commonwealth University Medical Center",
"Virginia Tech",
"Virginia Union University",
"Washington State University",
"Wentworth Institute of Technology",
"West Virginia University",
"Western Michigan University",
"Western State University - College of Law",
"Yale University"]
