import React, { useState } from 'react'
import FuzzBox from '../../components/FuzzBox'
import { UNIVERSITIES } from '../../constants/Universities'
import Select from 'react-select'
import DndPrefs from './DndPrefs'
import { useCookies } from 'react-cookie'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

const COOKIE_NAME = 'hupr'
const PREFS = [
  { id: 1, text: 'Cost', info: 'The amount for rent (all-in)' },
  { id: 2, text: 'Distance', info: 'How close you are to campus' },
  {
    id: 3,
    text: 'Amenities',
    info: 'Other benefits of the complex, like exercise room, pool, etc'
  },
  { id: 4, text: 'WiFi', info: 'How good is the network WiFi?' }
]

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

function ChooseUniversity({ shadowtext, value, update, reset, options }) {
  return (
    <div>
      <div className="mb2 mb3-m mb4-l white b  tc" style={shadowtext}>
        Student Housing has never been so easy!
      </div>
      <Select
        placeholder="Select your University"
        isSearchable={true}
        value={value}
        onChange={update}
        options={options}
      />
    </div>
  )
}

function ChoosePrefs({ shadowtext, value, update, reset, change }) {
  return (
    <div className="flex flex-column justify-center">
      <div className="mb2 mb3-m mb4-l white b tc" style={shadowtext}>
        From top to bottom, can you arrange these in order of importance to you?
      </div>

      <DndPrefs cards={value} setCards={change} />

      <div className="center mt3 mt4-l mt4-m">
        <button
          className="pa1 pa2-m pa2-l br2 bg-blue white i"
          onClick={e => {
            e.preventDefault()
            update(true)
          }}
        >
          Looks good!
          <i className="fas fa-play ml2 " />
        </button>
      </div>
    </div>
  )
}

function Start(props) {
  const options = UNIVERSITIES.map(name => ({ value: name, label: name }))

  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME])
  const cookie = cookies[COOKIE_NAME]
  const [selectedUniversity, updateSelectedUniversity] = useState(
    cookie ? cookie.university : false
  )
  const [selectedPrefs, updateSelectedPrefs] = useState(
    cookie ? cookie.prefs : false
  )
  const [selectedPrefsDone, updateSelectedPrefsDone] = useState(
    cookie && (cookie.prefs ? true : false) || false
  )
  const [cards, setCards] = useState(shuffleArray(PREFS))
  const selectUniversity = value => {
    updateSelectedUniversity(value)
    cookie.university = value
    setCookie(COOKIE_NAME, cookie, { path: '/' })
  }
  const selectPrefs = () => {
    updateSelectedPrefsDone(true)
    cookie.prefs = selectedPrefs
    setCookie(COOKIE_NAME, cookie, { path: '/' })
  }
  const reset = () => {
    updateSelectedPrefs(false)
    updateSelectedPrefsDone(false)
    updateSelectedUniversity(false)
    setCookie(COOKIE_NAME, {}, { path: '/' })
  }

  const shadowtext = {
    textShadow: '0 0 2px #000, 0 0 2px #000, 0 0 3px #000'
  }
  return (
    <>
      <div className="pt3 pt4-m pt6-l tc ">
        <img src="/homeu4-final-150.png" />
      </div>
      <div className="mt3 pa1 pa4-m pa5-l tl flex justify-center ">
        <div className="w-50-m w-50-l ">
          { !selectedUniversity ? (
            <ChooseUniversity
              shadowtext={shadowtext}
              value={selectedUniversity}
              update={selectUniversity}
              reset={reset}
              options={options}
            />
          ) : !selectedPrefsDone ? (
            <ChoosePrefs
              shadowtext={shadowtext}
              value={cards}
              update={selectPrefs}
              change={updateSelectedPrefs}
              reset={reset}
            />
          ) : (
            <FuzzBox tint="light" className="pa2 pa3-l pa3-m">
              <div className="mb2 mb3-m mb4-l">
                Thank You!
              </div>
              <div className="mb2 mb3-m mb4-l">
                We are busy completing the rest of this tool which will help you
                find the appartment you will love while attending school. Check
                back soon!
              </div>
              <div className="">
                <button
                  className="pa1 br2"
                  onClick={e => {
                    e.preventDefault()
                    reset()
                  }}
                >
                  Reset Preferences
                </button>
              </div>
            </FuzzBox>
          )}
        </div>
      </div>
    </>
  )
}
// <div className="mt2 mt3-m mt4-l white b tc" style={ shadowtext }>

export default Start
