import gql from 'graphql-tag'

const profileValues = `
    id
    emails {id,address,primary,verified}
    handle
    name
    phones {number,primary,verified}
    settings
    access { roles, actions }
`

export const READ_PROFILE = gql`
{
  myProfile {
    ${profileValues}
  }
}
`

export const UPDATE_PROFILE = gql`
mutation UpdateProfile($name: String!) {
  updateProfile(name: $name) {
    ${profileValues}
  }
}
`

// todo: add pagination and better filtering
export const LIST_PROFILES = gql`
query listProfiles($matching: String) {
  listProfiles(matching: $matching) {
    ${profileValues}
  }
}
`
