/*
 Authentication View.
*/

import React, { useState } from 'react'
// import debug from '../../utils/debug'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SignOnLocalTab from './SignOnLocalTab'

////////////////////////////////////////////////////////////////////////////////
export function SignOnLocal(props) {
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)

  const [tabHeaders, setTabHeaders] = useState([
    { label: 'SIGN IN' },
    { label: 'Sign up' }
  ])

  const tabClick = (index, lastIndex, event) => {
    let changed = []
    let current = tabHeaders
    for (let x = 0; x < current.length; x++) {
      let elem = current[x]
      if (x === index) {
        elem.label = elem.label.toUpperCase()
      } else {
        elem.label =
          elem.label.charAt(0).toUpperCase() + elem.label.slice(1).toLowerCase()
      }
      changed.push(elem)
    }
    setStatus('')
    setLoading(false)
    setTabHeaders(changed)
  }

  return (
    <Tabs onSelect={tabClick}>
      <TabList>
        {tabHeaders.map((x, index) => {
          return <Tab key={index}>{tabHeaders[index].label}</Tab>
        })}
      </TabList>
      <TabPanel>
        <SignOnLocalTab
          label="Sign In"
          signup={false}
          boxPadding={props.boxPadding}
          tenant={props.tenant}
          context={props.context}
          status={{ status, setStatus }}
          loading={{ loading, setLoading }}
        />
      </TabPanel>
      <TabPanel>
        <SignOnLocalTab
          label="Sign Up"
          signup={true}
          boxPadding={props.boxPadding}
          tenant={props.tenant}
          context={props.context}
          status={{ status, setStatus }}
          loading={{ loading, setLoading }}
        />
      </TabPanel>
    </Tabs>
  )
}

export default SignOnLocal
