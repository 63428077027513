import React from 'react'
import background from './signin.jpg' // eslint-disable-line
import NavBar from './NavBar'

const sizes = [1440, 720, 480]
const backgrounds = [
  'ashley-richards-OAt9NFET9WE-unsplash',
  'eliott-reyna-jCEpN62oWL4-unsplash',
  'nguyen-dang-hoang-nhu-HHs_PrvxSQk-unsplash',
  'ryan-jacobson-cXUOQWdRV4I-unsplash',
  'shutterstock_685407757',
  'shutterstock_128459306'
]

function UserProfile(props) {
  const { context } = props
  const img = backgrounds[Math.floor(Math.random() * backgrounds.length)]
  const srcset = sizes.map(size => {
    return {
      url: `/${img}-${size}.jpg`,
      width: size
    }
  })

  return (
    <div className="bgWrapper">
      <div className="background img-pulse" style={{ backgroundImage: 'url(' + srcset[0].url + ')' }} />
      <div className="bgContent">
        <NavBar context={context} />
        {props.children}
      </div>
    </div>
  )
}

export default UserProfile
