import React, { useState, useCallback } from 'react'
import Card from './DndPrefsCard'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

////////////////////////////////////////////////////////////////////////////////
export function Container({ cards, setCards }) {
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      )
    },
    [cards]
  )

  return (
    <DndProvider backend={Backend}>
      <div className="w-50-m w-50-l center">
        {cards.map((card, index) => (
          <Card key={card.id} index={index} moveCard={moveCard} {...card} />
        ))}
      </div>
    </DndProvider>
  )
}

export default Container
