import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './App'
import * as serviceWorker from './utils/serviceWorker'
import { ApolloProvider } from 'react-apollo'
import context from './constants/context.js'
import apollo from './apollo.js'
import { CookiesProvider } from 'react-cookie';

// enrich
context.apollo = apollo

// only include high level providers and configuration things here
ReactDOM.render(
  <CookiesProvider>
    <ApolloProvider client={context.apollo}>
      <App context={context} />
    </ApolloProvider>
  </CookiesProvider>,
  document.getElementById('root')
)
serviceWorker.unregister()
