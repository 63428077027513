import React, { useState } from 'react'
import { AuthX, Start, Root } from './screens'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

// base class
export function App({ context }) {
  const [userSignedIn, updateUserSignedIn] = useState(false)
  const [userProfile, updateUserProfile] = useState(undefined)

  context.signedIn = { active: userSignedIn, update: updateUserSignedIn }
  context.userProfile = { current: userProfile, update: updateUserProfile }

  // NOTE: REFLECT THESE ROUTES ON THE SERVER IN NGINX CONFIG
  return (
    <Router>
      <Root context={context}>
        <Switch>
          <Route exact path="/">
            <Start context={context} />
          </Route>
          <Route path="/signon">
            <AuthX context={context} />
          </Route>
          <Route path="/signout">
            <AuthX context={context} />
          </Route>
          {userSignedIn ? (
            <Route path="/signout">
              <AuthX context={context} />
            </Route>
          ) : (
            <></>
          )}
        </Switch>
      </Root>
    </Router>
  )
}

export default App
