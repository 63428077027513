// import { useHistory } from 'react-router-dom';
const debug = (x, y) => {}
// import debug from './debug'

export const LAST_NAV = 'pf-nav'

export function lastNav() {
  debug('[NavBar].lastNav()', '')
  // Reconsider: problem where /map/xyzid for one user, signout, then login as
  // next user, and still have context from old user - securityrisk.
  // see Auth/index:signonComplete
  return localStorage.getItem(LAST_NAV)
}

// pass-in useHistory() from a react component.  Ala:
//
//     navigate(useHistory(), "/boop")
//
export function navigate(history, dst) {
  debug('[history].navigate(h, dst) dst=', dst)

  let to = dst
  // pull from history, and/or remember things in history
  if (to === undefined) {
    to = lastNav()
    if (to === undefined) {
      to = '/'
    }
    debug('[history].lastNav =>', to)
  } else if (!['/signon', '/signout'].includes(to)) {
    localStorage.setItem(LAST_NAV, to)
    debug('[history].setItem =>', to)
  }

  // call via our stateful history
  debug('[history].navigate to=', to)
  // const history = useHistory()
  /*
  if (props && props.context) {
    console.log("Using")
    history = props.context.history

    // not sure why this happens
    if (!history) {
      props.context.history = createBrowserHistory()
      history = props.context.history
    }
  } else {
    console.log("new")
    history = createBrowserHistory()
  }

    console.log("push", history)
  */
  history.push(to || '/')
}

// this is just for readability
export const navigateBackwards = navigate
