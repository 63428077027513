/*
 Authentication View.
*/

import React from 'react'
import style from './index.module.scss'
import { doSignOnLocal } from './doSignOnLocal'
import StatusBox from './StatusBox'

////////////////////////////////////////////////////////////////////////////////
function SignOnLocalTab({
  label,
  signup,
  status,
  loading,
  tenant,
  context,
  boxPadding
}) {
  let explain = {
    email: 'Your email',
    password: 'Your password'
  }

  if (label !== 'Sign In') {
    explain.password = 'Your desired password'
  }

  let handle
  let password

  return (
    <div className={`${boxPadding} pt3 pt4-ns items-center`}>
      <form>
        <input
          ref={node => {
            handle = node
          }}
          className="w-100 br2"
          placeholder={explain.email}
        />
        <input
          ref={node => {
            password = node
          }}
          className="w-100 br2"
          type="password"
          placeholder={explain.password}
        />

        <div className="flex justify-around mt3 items-center">
          <button
            className={`button auth-signin ${style.greenButton} w-100 items-center pa2`}
            style={{ border: 0, margin: 0 }}
            onClick={e => {
              e.preventDefault()
              status.setStatus('')
              doSignOnLocal({
                signup,
                handle,
                password,
                context,
                loading,
                status
              })
            }}
          >
            <small className="label pl2">{label}</small>
          </button>
        </div>
        <StatusBox message={status.status} loading={loading.loading} />
      </form>
    </div>
  )
}
export default SignOnLocalTab
