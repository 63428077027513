import React from 'react'
import style from './index.module.scss'
import { LoadingInline } from '../../components/Handlers'

////////////////////////////////////////////////////////////////////////////////
export function StatusBox({ loading, message }) {
  return (
    <div className="tc fw2">
      {loading ? (
        <div
          className="pa2 w-100 flex justify-center mt2"
          style={{ textAlign: 'center' }}
        >
          <LoadingInline>Checking...</LoadingInline>
        </div>
      ) : (
        <></>
      )}
      {message ? (
        <div className={`${style.authstatus} visible pa2 mt2`}>{message}</div>
      ) : (
        <div className={`${style.authstatus} hidden`}></div>
      )}
    </div>
  )
}

export default StatusBox
