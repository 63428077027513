let context = {
  config: {
    baseurl: '',
    graphql: '/graphql',
    authapi: '/authx/v1/api/'
  },
  apollo: undefined,
  user: {},
  signedIn: { active: false, update: undefined },
  userProfile: { current: undefined, update: undefined }
}

switch (process.env['NODE_ENV']) {
  case 'development':
    context.config.baseurl = 'http://localhost:4000'
    break
  default:
    break
}

export default context
