import { READ_PROFILE } from '../../constants/Profile'
import { authDebug } from './index'
// const authDebug = (x, y, z) => {}

////////////////////////////////////////////////////////////////////////////////
export function getProfile(context) {
  authDebug(1, '[authx/profile] getProfile()')
  const { apollo, userProfile } = context

  apollo.query({ query: READ_PROFILE }).then(({ data: { myProfile } }) => {
    authDebug(1, '[authx/profile] getProfile() - updating profile')
    userProfile.update({
      profile: redactedUserProfile(myProfile),
      context
    })
  })
}

////////////////////////////////////////////////////////////////////////////////
export function redactedUserProfile(profile) {
  authDebug(3, '[authx/profile] redactedProfile()')
  return {
    roles: new Set(profile.access.roles),
    actions: new Set(profile.access.actions),
    loaded: true,
    id: profile.id,
    // eventually remove this
    _raw: profile
  }
}
export function myProfile({ userProfile: { current } }) {
  if (current) {
    return current.profile
  } else {
    return {}
  }
}
export function hasRole(role, { userProfile: { current } }) {
  console.log(
    'hasRole() is deprecated, switch to authCan() using actions instead of roles'
  )
  authDebug(3, '[authx/profile] hasRole()', role)
  if (current) {
    return current.profile.roles.has(role)
  }
  return false
}
export function authCan(action, { userProfile: { current } }) {
  authDebug(3, '[authx/profile] authCan()', action)
  if (current) {
    return current.profile.actions.has(action)
  }
  return false
}
